<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";

import List from './list'
import Form from './form'
import FormMultiple from './form-multiple'
import StockOpname from './stockopname.vue'

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    List,
    Form,
    FormMultiple,
    StockOpname,
  },
  data() {
    return {
      title: "Warehouse Inventory",
      items: [
        {
          text: "VMCore"
        },
        {
          text: "Warehouse"
        },
        {
          text: "Inventory",
          active: true
        }
      ]
    };
  },
  methods :{
    onActiveChange(b) {
      this.$refs.list.setActive(!b)
    },
    onRefresh(){
      this.$refs.list.fetchData()
    },
    onEdit(id){
      this.$refs.form.initUpdate(id)
    },
    onCreate(){
      this.$refs.form.invokeCreate()
    },
    onCreateMultiple(id){
      this.$refs.form_multiple.initUpdate(id)
    },
    onViewStockOpname(id){
      this.$refs.stockopname.invokeCreate(id)
    }
  },
  computed: {
    warehouse() {
      return this.$route.params
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-12">
        <Form ref="form" @refresh="onRefresh" @active_change="onActiveChange"/>
        <FormMultiple ref="form_multiple" @refresh="onRefresh" @active_change="onActiveChange"/>
        <StockOpname ref="stockopname" @refresh="onRefresh" @active_change="onActiveChange"/>
        <List ref="list" @onEdit="onEdit" @onCreate="onCreate" @onCreateMultiple="onCreateMultiple" @onViewStockOpname="onViewStockOpname" />
      </div>
    </div>
  </Layout>
</template>