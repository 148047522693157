<script>
import { inventoryService } from "@/helpers/backend/inventory.service"
import { productService } from "@/helpers/backend/product.service"

export default {
  data() {
    return {
      form: {
        id: null,
        product_id: null,
        location_id: null,
        quantity: 0,
        price: 0,
      },
      product_options: [{ text: "Select One", value: null }],
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    if (!this.warehouse.id) {
      this.$router.back()
    }
    this.form.location_id = this.warehouse.id
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
    warehouse() {
      return this.$route.params
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.busy = true;
      this.form.location_id = this.warehouse.id
      inventoryService.createOrUpdateInventory(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Insert/Update Warehouse Inventory. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      // Reset our form values
      this.form.id = null;
      this.form.product_id = null;
      this.form.quantity = 0;
      this.form.price = 0;
      this.form.location_id = null
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    onProductSelect(product_id) {
      const productOption = this.product_options.find(mProduct => mProduct.value == product_id)
      this.form.price = productOption.data.price
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.fetchData(true)
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.busy = true
        inventoryService.getInventoryByID(id).then(
          (data) => {
            if (data.inventory != null) {
              this.form.id = data.inventory.id
              this.form.product_id = data.inventory.product.id
              this.form.quantity = data.inventory.quantity
              this.form.price = data.inventory.price
            } else {
              this.showDismissibleAlert = true
              this.error =
                "Failed to get inventory type to update. Error : inventory not found"
            }
            this.busy = false
          },
          (err) => {
            this.showDismissibleAlert = true
            this.error =
              "Failed to get inventory type to update. Error : " + err
            this.busy = false
          }
        )
      })
    },
    invokeCreate() {
      this.setActive(true)
    },
    fetchData(is_update = false) {
      let filter = {}
      this.form.location_id = this.warehouse.id

      if (!is_update) {
        filter = { ex_location_id: this.warehouse.location_id }
      }
      // Filter product if already exist in inventory
      productService.getAllProduct(filter).then(
        (data) => {
          this.product_options = this.product_options.concat(
            data.products.map(function (mProduct) {
              return { text: mProduct.name, value: mProduct.id, data: mProduct }
            })
          )
          //alert(typeof(this.machine_types))
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get products. Error : " + err
        }
      )
    }
  },
};
</script>

<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-form-group
                id="input-group-1"
                label="Select Product:"
                label-for="input-1"
              >
                <b-form-select
                  v-model="form.product_id"
                  :options="product_options"
                  @change="onProductSelect"
                  required
                >
                </b-form-select>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                label="Quantity :"
                label-for="input-2"
              >
                <b-form-input
                  id="input-2"
                  v-model="form.quantity"
                  type="number"
                  placeholder="Quantity"
                  required
                />
              </b-form-group>
              <b-form-group
                id="input-group-3"
                label="Price :"
                label-for="input-3"
              >
                <b-form-input
                  id="input-3"
                  v-model="form.price"
                  type="number"
                  placeholder="Price"
                  required
                />
              </b-form-group>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
  </div>
  <!-- <div v-else>
    <b-form-group>
      <b-button variant="primary" @click="invokeCreate"
        >Add new product</b-button
      >
    </b-form-group>
  </div> -->
</template>



