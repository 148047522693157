<script>
import { inventoryService } from "@/helpers/backend/inventory.service"
import { productService } from "@/helpers/backend/product.service"

export default {
  data() {
    return {
      form: {
        location_id: null,
        inventories: [],
      },
      location_id: null,
      product_options: [{ text: "Select One", value: null }],
      fields: [
        { key: "product_name", sortable: false, label: "Product Name" },
        { key: "price", sortable: false, label: "Inventory Price" },
        { key: "quantity", sortable: false, label: "Inventory Quantity" },
        { key: "action", sortable: false, label: "Action" },
      ],
      filter: null,
      sortBy: "id",
      sortDesc: false,
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    if (!this.warehouse.id) {
      this.$router.back()
    }

    this.form.location_id = this.warehouse.id
    this.location_id = this.warehouse.id
    this.fetchData()
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
    warehouse() {
      return this.$route.params
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.busy = true;
      this.error = null
      this.form.location_id = this.location_id

      this.form.inventories.forEach(inventory => {
        if (inventory.product_id == null) {
          this.error = "there's empty product."
        }else if(inventory.quantity < 1){
          this.error = "quantity must be more than 0."
        }
      });

      if (this.error != null) {
        this.showDismissibleAlert = true
        this.error = "Failed to Insert/Update Inventory. Error : " + this.error
        this.busy = false
        return
      }else{
        inventoryService.updateInventories(this.form).then(
          (data) => {
            if (data != null) {
              this.reset()
              this.$emit("refresh")
              this.showDismissibleAlert = true
              this.error = ""
              this.setActive(false)
            }
            this.busy = false
          },
          (error) => {
            this.showDismissibleAlert = true
            this.error =
              "Failed to Insert/Update Inventory. Error : " + error
            this.busy = false
          }
        )
      }
    },
    reset() {
      this.form.location_id = null;
      this.form.inventories = [];
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    initUpdate(id) {
      this.setActive(true)

      this.$nextTick(() => {
        this.reset()
        this.busy = true
        inventoryService.getInventoryByLocationID(id).then(
          data=> {
            this.form.inventories = data.inventories;
            this.busy = false;
          }, 
          err => {
            this.error = "Failed to fetch inventories data. Error : " + err + "  Dissmiss to refresh"
            this.busy = false
            this.showDismissibleAlert = true
          }
        )
      })
    },
    fetchData(is_update = false) {
      let filter = {}
      if (!is_update) {
        filter = { ex_location_id: this.location_id }
      }
      // Filter product if already exist in inventory
      productService.getAllProduct(filter).then(
        (data) => {
          this.product_options = this.product_options.concat(
            data.products.map(function (mProduct) {
              return { text: mProduct.name, value: mProduct.id, data: mProduct }
            })
          )
        },
        (err) => {
          this.showDismissibleAlert = true
          this.error = "Failed to get products. Error : " + err
        }
      )
    },
    removeRow(idx){
      this.form.inventories = this.form.inventories.filter((item, i) => i !== idx);
    },
    addNewRow(){
      let newRow = this.fields.reduce((a, c) => ({...a, [c.key]: null}) ,{})
      newRow.product_id = null
      newRow.price = 0
      newRow.quantity = 0
      this.form.inventories.unshift(newRow);
    },
  },
};
</script>

<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>

    <b-form-group>
      <b-button variant="primary" class="mr-4" @click="addNewRow">Add new product</b-button>
    </b-form-group>

    <div class="card">
      <div class="card-body">
        <div>
          <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
            <b-overlay :show="busy" rounded="lg" opacity="0.6">
              <b-table
                :items="form.inventories"
                :fields="fields"
                responsive="sm"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
              >
                <template #cell(product_name)="row">
                  <b-form-select
                    id="input-name"
                    v-model="row.item.product_id"
                    :options="product_options"
                  >
                  </b-form-select>
                </template>
                <template #cell(price)="row">
                  <b-form-input v-model.number="row.item.price" type="number" />
                </template>
                <template #cell(quantity)="row">
                  <b-form-input v-model.number="row.item.quantity" type="number" />
                </template>
                <template #cell(action)="row">
                  <div v-if="!!row.item.id">
                    -
                  </div>
                  <div v-else>
                    <a 
                      @click="removeRow(row.index)" class="mr-3 text-primary" v-b-tooltip.hover data-toggle="tooltip" title="Remove Row">
                     <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </div>
              
                </template>
              </b-table>

              <b-button class="mr-2" type="submit" variant="primary">
                {{ isUpdateState ? "Update" : "Submit" }}
              </b-button>
              <b-button type="reset" variant="danger">Cancel</b-button>
            </b-overlay>
          </b-form>
        </div>
      </div>
    </div>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
  </div>
</template>



