import { authHeader } from './auth-header'
import axiosInstance from "./client"

export const warehouseService = {
    getAll,
    createOrUpdate,
    getById,
    deleteById,
    getAllRegion,
    createOrUpdateRegion,
    deleteRegionById,
    getRegionById,
    stockOpname,
};

function getById(machineTypeId) {
    const requestOptions = {
        url: '/api/v1/user/warehouse/' + machineTypeId,
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
    //return fetch(`/api/v1/user/machine_type/` + machineTypeId, requestOptions).then(handleResponse);
}

function getAll() {
    // console.log(process.env)
    const requestOptions = {
        url: '/api/v1/user/warehouse',
        method: 'GET',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}


function createOrUpdate(jsonData) {

    // let formData = new FormData();
    // 
    // for ( var key in jsonData ) {
    //     if (jsonData[key] != null)
    //         formData.append(key, jsonData[key]);
    // }
    // let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    // const requestOptions = {
    //     url : '/api/v1/user/warehouse',
    //     method: rMethod,
    //     data: formData,
    //     headers: authHeader()
    // };

    let rMethod = jsonData["id"] != null ? "PUT" : "POST"
    const requestOptions = {
        url: `/api/v1/user/warehouse`,
        method: rMethod,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(jsonData)
    }

    return axiosInstance(requestOptions)
}


function deleteById(id) {
    const requestOptions = {
        url: `/api/v1/user/warehouse/` + id,
        method: 'DELETE',
        headers: authHeader()
    }
    return axiosInstance(requestOptions)
}

function getAllRegion(){
    const requestOptions = {
        url : '/api/v1/user/region',
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance( requestOptions)
}

function getRegionById(regionId) {
    const requestOptions = {
        url : '/api/v1/user/region/' + regionId,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function createOrUpdateRegion(jsonData) {
    let formData = new FormData();
    jsonData['ID'] = jsonData['id']
    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : '/api/v1/user/region',
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

function deleteRegionById(id) {
    const requestOptions = {
        url: `/api/v1/user/region/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}

function stockOpname(jsonData) {

    let rMethod = "POST"
    const requestOptions = {
        url: `/api/v1/user/warehouse/stockopname`,
        method: rMethod,
        headers: { 'Content-Type': 'application/json' },
        data: JSON.stringify(jsonData)
    }

    return axiosInstance(requestOptions)
}