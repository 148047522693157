<script>
import { inventoryService } from "@/helpers/backend/inventory.service"
import { warehouseService } from "@/helpers/backend/warehouse.service"
// import { productService } from "@/helpers/backend/product.service"
import { XlsxRead
// , XlsxTable
// , XlsxSheets
, XlsxJson
,XlsxWorkbook
, XlsxSheet
, XlsxDownload 
} from "vue-xlsx/dist/vue-xlsx.es.js"

export default {
  components:{
    XlsxRead,
    // XlsxTable,
    // XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  data() {
    return {
      stock_opname_file: "stock_opname_warehouse",
      exported_fields: {
        'Product SKU': 'product_sku' ,
        'Product Name': 'product.name' ,
        'Uploaded Quantity': 'opname_qty',
        'Warehouse Quantity': 'quantity',
        'Difference': 'difference',
        'Issue Type': 'issue_type' ,
      },
      stockFile: null,
      selectedSheet: null,
      sheetName: null,
      sheets: [],

      form: {
        location_id: null,
        inventories: [],
      },
      issue_type_options: [
        { text: "Select One", value: null }, 
        { text: "Expired", value: "expired"},
        { text: "Discrepancy", value: "discrepancy"},
        { text: "Fault", value: "fault"},
        { text: "Missing", value: "missing"},
        { text: "Stuck", value: "stuck"},
        { text: "Others", value: "others"}
      ],
      product_options: [{ text: "Select One", value: null }],
      fields: [
        { key: "product_sku", sortable: true, label: "Product SKU" },
        { key: "product.name", sortable: true, label: "Product Name" },
        { key: "opname_qty", sortable: true, label: "Uploaded Quantity" },
        { key: "quantity", sortable: true, label: "Warehouse Quantity" },
        { key: "difference", sortable: true, label: "Difference" },
        { key: "issue_type", sortable: true, label: "Issue Type" },
      ],
      dataTemplateFields: { "product_sku": "example: 1234567890", "opname_qty": 10 },
      dataTemplate: [],
      filter: null,
      sortBy: "product.sku",
      sortDesc: false,
      busy: false,
      show: true,
      showDismissibleAlert: false,
      error: "",
      active: false,
    }
  },
  created() {
    if (!this.warehouse.id) {
      this.$router.back()
    }
    this.form.location_id = this.warehouse.location_id
    this.fetchData()
    this.dataTemplate.push(this.dataTemplateFields)
  },
  computed: {
    isUpdateState() {
      return this.form.id != null
    },
    warehouse() {
      return this.$route.params
    }
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.busy = true;
      this.error = null
      // console.log(this.form)

      warehouseService.stockOpname(this.form).then(
        (data) => {
          if (data != null) {
            this.reset()
            this.$emit("refresh")
            this.showDismissibleAlert = true
            this.error = ""
            this.setActive(false)
          }
          this.busy = false
        },
        (error) => {
          this.showDismissibleAlert = true
          this.error =
            "Failed to Stock Opname. Error : " + error
          this.busy = false
        }
      )
    },
    reset() {
      this.form.location_id = null;
      this.form.inventories = [];
      this.show = false
      this.showDismissibleAlert = false
      this.error = ""
      this.$nextTick(() => {
        this.show = true
      })
      this.fetchData()
    },
    onReset(event) {
      event.preventDefault()
      this.setActive(false)
      this.reset()
    },
    setActive(b, outside_triger) {
      this.active = b
      if (outside_triger) return
      this.$emit("active_change", b)
    },
    invokeCreate(id) {
      this.setActive(true)
      // this.location_id = id
      this.form.location_id = id
    },
    fetchData() {
      this.stock_opname_file = "stock_opname_warehouse" + this.form.location_id
      inventoryService.getInventoryByLocationID(this.form.location_id).then(
        data => {
          this.form.inventories = data.inventories;
          this.loading = false;
          this.sheets = [{ name: "Sheet1", data: data.inventories }]
          this.selectedSheet = this.sheets[0].name
      

        }, 
        err => {
          this.error = "Failed to fetch inventory data. Error : " + err + "  Dissmiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
    },
    onChange(event) {
      this.stockFile = event.target.files ? event.target.files[0] : null;
      this.selectedSheet = this.sheets[0].name
    },
    getData (collection) {
      // if there is product in uploaded data but the product in warehouse not exists
      if (collection != null) {
        let checkExists = null
        collection.forEach(c => {          
          checkExists = this.form.inventories.find((inven) => inven.product.sku == c.product_sku)
          if (checkExists == null) { // if no product in warehouse 
            c.product = {
              'sku': c.product_sku,
              'name': ''
            }
            c.issue_type = null
            c.quantity = 0
            c.difference = -c.opname_qty
            if(c.difference != 0) 
              c.issue_type = "discrepancy"
            this.form.inventories.push(c)
          }
        }); 
      }

      // set data to form
      this.form.inventories.forEach(inventory => {
        inventory.product_sku = inventory.product.sku
        inventory.issue_type = null

        if(collection != null){
          inventory.upload_data = collection.find((c) => c.product_sku == inventory.product_sku)
          inventory.opname_qty = inventory.upload_data == null? 0 : inventory.upload_data.opname_qty
          inventory.difference = inventory.quantity - inventory.opname_qty
          if(inventory.difference != 0) 
            inventory.issue_type = "discrepancy"
        }else{
          inventory.opname_qty = 0
          inventory.difference = 0
        }
      
      });

      return this.form.inventories
    },
    
  },
};
</script>

<template>
  <div v-if="this.active">
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>

    <b-form-group>
      <div style="display:inline-flex; align-items: center;">
        <b-form-file
          v-model="stockFile"
          placeholder="Upload Stock File"
          @change="onChange"
           class="mr-2"
        ></b-form-file>

        <!-- <download-excel type="xls" name="stock_opname_template" :data="dataTemplate" style="width: -webkit-fill-available">
          <b-button variant="light" class="mr-4" >Download Template
            <i class="ri-download-2-line"></i>
          </b-button>
        </download-excel> -->
        <xlsx-workbook>
          <xlsx-sheet
            :collection="dataTemplate"
            v-for="sheet in sheets"
            :key="sheet.name"
            :sheet-name="sheet.name"
          />
          <xlsx-download
            filename="stock_opname_template.xlsx"
          >
            <b-button variant="light" class="mr-4" style="width: max-content;">Download Template
              <i class="ri-download-2-line"></i>
            </b-button>
          </xlsx-download>
        </xlsx-workbook>

      </div>
    </b-form-group>

    <div class="card">
      <div class="card-body">
        <div>

          <xlsx-read :file="stockFile">
            <xlsx-json :sheet="selectedSheet">
              <template #default="{collection}">
                <b-form @submit="onSubmit" @reset="onReset" v-if="show" ref="form">
                  <b-table
                    :items="getData(collection)"
                    :fields="fields"
                    responsive="sm"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                  >
                  <template v-slot:cell(difference)="row">
                    <div
                      :class="{
                        '': row.item.difference == 0,
                        'badge font-size-12 badge-danger': row.item.difference != 0,
                      }"
                    >
                      {{ row.item.difference }}
                    </div>
                  </template>

                  <template v-slot:cell(issue_type)="row">
                    <b-form-select id="input-type" v-model="row.item.issue_type" :options="issue_type_options">
                    </b-form-select>
                  </template>

                  </b-table>

                  <div style="display:flex;">
                    <b-button class="mr-2" type="submit" variant="primary">
                      Submit
                    </b-button>

                    <download-excel type="xls" :name="stock_opname_file" :data="form.inventories" :fields="exported_fields">
                      <b-button variant="light" class="mr-4" >Download Stock Opname Result
                        <i class="ri-download-2-line"></i>
                      </b-button>
                    </download-excel>
                  </div>
                  
                </b-form>
              </template>
            </xlsx-json>
            
          </xlsx-read>

          
        </div>
      </div>
    </div>
    <b-alert
      :variant="error == '' ? 'success' : 'danger'"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="showDismissibleAlert = false"
    >
      <div v-if="error != ''">
        {{ error }}
      </div>
      <div v-else>Success perform Insert/Update action</div>
    </b-alert>
  </div>
</template>



