import { authHeader } from './auth-header';
import axiosInstance from "./client"

export const productService = {
    getAllProduct,
    getAllProductType,
    createOrUpdateProduct,
    createOrUpdateProductType,
    getProductByID,
    getProductTypeByID,
    deleteProductByID,
    deleteProductTypeByID,
};


function getAllProduct(filter) {
    const requestOptions = {
        url : `/api/v1/user/product`,
        method: 'GET',
        params: filter,
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getProductByID(id) {
    const requestOptions = {
        url : `/api/v1/user/product/` + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getProductTypeByID(id) {
    const requestOptions = {
        url : `/api/v1/user/product_type/` + id,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}

function getAllProductType() {
    const requestOptions = {
        url : `/api/v1/user/product_type`,
        method: 'GET',
        headers: authHeader()
    };
    return axiosInstance(requestOptions)
}


function createOrUpdateProduct(jsonData) {

    let formData = new FormData();

    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : `/api/v1/user/product`,
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}

function createOrUpdateProductType(jsonData) {

    let formData = new FormData();

    for ( var key in jsonData ) {
        if (jsonData[key] != null)
            formData.append(key, jsonData[key]);
    }
    let rMethod = jsonData["id"] != null  ? "PUT" : "POST"
    const requestOptions = {
        url : `/api/v1/user/product_type`,
        method: rMethod,
        data: formData,
        headers: authHeader()
    };

    return axiosInstance(requestOptions);
}


function deleteProductByID(id) {
    const requestOptions = {
        url : `/api/v1/user/product/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}


function deleteProductTypeByID(id) {
    const requestOptions = {
        url : `/api/v1/user/product_type/` + id,
        method: 'DELETE',
        headers: authHeader()
    };
    return axiosInstance(requestOptions);
}
