<script>
import { inventoryService } from '@/helpers/backend/inventory.service'
/**
 * Transactions component
 */
export default {
  data() {
    return {
      data: [],
      model_filter: {
        location_id: null,
      },
      filter_type: "",
      location_type_options: [{ text: 'Machine', value: 'machine' }, { text: 'Warehouse', value: 'warehouse' }, { text: 'Vehicle', value: 'vehicle' }],
      location_options: {
        machine: [{ text: 'Select One', value: null }],
        warehouse: [{ text: 'Select One', value: null }],
        vehicle: [{ text: 'Select One', value: null }],
      },
      loading: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true, label: "Inventory id" },
        { key: "product.name", sortable: true, label: "Product Name" },
        { key: "product.price", sortable: true, label: "Product Price" },
        { key: "price", sortable: true, label: "Inventory Price" },
        { key: "quantity", sortable: true, label: "Inventory Quantity" },
        { key: "location", sortable: true, label: "Location" },
        { key: "action" }
      ],
      showDismissibleAlert: false,
      active: true,
    }
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.data.length
    },
    warehouse() {
      return this.$route.params
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.data.length
  },
  methods: {
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onUpdate(id) {
      this.$emit('onEdit', id)
    },
    setActive(b) {
      this.active = b
    },
    invokeCreate() {
      this.$emit('onCreate')
    },
    invokeMultipleCreate() {
      this.$emit('onCreateMultiple', this.warehouse.location_id)
    },
    invokeStockOpname() {
      this.$emit('onViewStockOpname', this.warehouse.location_id)
    },
    refreshList() {
      // inventoryService.getAll({
    //     location_id: this.warehouse.location_id,
    //   }).then(
    //     data=> {
    //       this.data = data.inventories;
    //       this.loading = false;
    //     }, 
    //     err => {
    //         this.error = "Failed to fetch inventory type data. Error : " + err + "  Dissmiss to refresh";
    //         this.loading = false;
    //         this.showDismissibleAlert=true
    //     }
    //   )
    // },
      inventoryService.getInventoryByLocationID(this.warehouse.location_id).then(
        data=> {
          this.data = data.inventories;
          this.loading = false;
        }, 
        err => {
          this.error = "Failed to fetch inventory type data. Error : " + err + "  Dissmiss to refresh"
          this.loading = false
          this.showDismissibleAlert = true
        }
      )
    },
    fetchData() {
      this.error = null
      this.loading = true
      this.showDismissibleAlert = false
      this.refreshList()
      inventoryService.getAllLocation().then(
        data => {
          data.locations.forEach(location => {
            var location_type = ""
            var location_name = ""
            if (location.machine_id.Valid) {
              location_type = "machine"
              location_name = location.machine.serial_number
            } else if (location.warehouse_id.Valid) {
              location_type = "warehouse"
              location_name = location.warehouse.location_name
            } else if (location.vehicle_id.Valid) {
              location_type = "vehicle"
              location_name = location.vehicle.name + "(" + location.vehicle.vehicle_number + ")"
            }
            this.location_options[location_type].push({ text: location_name, value: location.id })
          })
          this.loading = false
        }
      )
    }
  }
};
</script>
<template>
  <div v-if="this.active">
    <b-alert
      variant="danger"
      dismissible
      fade
      :show="showDismissibleAlert"
      @dismissed="fetchData"
    >
      {{ error }}
    </b-alert>
    
    <b-form-group>
      <b-button variant="primary" class="mr-4" @click="invokeCreate">Add new product</b-button>
      <b-button variant="primary" class="mr-4" @click="invokeMultipleCreate">Multiple add / update products</b-button>
      <b-button variant="success" class="mr-4" @click="invokeStockOpname">Stock Opname</b-button>
    </b-form-group>

    <div class="card">
      <div class="card-body">
        <div class="row mt-4">
          <div class="col-sm-12 col-md-4">
            <div id="tickets-table_length" class="dataTables_length">
              <label class="d-inline-flex align-items-center">
                Show&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;entries
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-4">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-right"
            >
              <label class="d-inline-flex align-items-center">
                Search:
                <b-form-input
                  v-model="filter"
                  type="search"
                  class="form-control form-control-sm ml-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <div class="table-responsive">
          <b-table
            :items="data"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template v-slot:cell(location)="item">
              {{
                item.item.location.machine_id.Valid
                  ? item.item.location.machine.serial_number
                  : ""
              }}
              {{
                item.item.location.warehouse_id.Valid
                  ? item.item.location.warehouse.name
                  : ""
              }}
              {{
                item.item.location.vehicle_id.Valid
                  ? item.item.location.vehicle.name +
                    "(" +
                    item.item.location.vehicle.vehicle_number +
                    ")"
                  : ""
              }}
              <div class="badge font-size-12 badge-soft-success">
                {{ item.item.location.machine_id.Valid ? "Machine" : "" }}
                {{ item.item.location.warehouse_id.Valid ? "Warehouse" : "" }}
                {{ item.item.location.vehicle_id.Valid ? "Vehicle" : "" }}
              </div>
            </template>
            <template v-slot:cell(action)="row">
              <a
                @click="onUpdate(row.item.id)"
                class="mr-3 text-primary"
                v-b-tooltip.hover
                data-toggle="tooltip"
                title="Edit"
              >
                <i class="mdi mdi-pencil font-size-18"></i>
              </a>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-right">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>